import React from 'react'
import './Map.css'
// import { Icon } from '@iconify/react'
// import locationIcon from '@iconify/icons-mdi/map-marker'


// const LocationPin = () => (
//     <div className="pin">
//         <Icon icon={locationIcon} className="pin-icon" />
//     </div>
// )

const Map = ({ location, zoomLevel }) => (
    <div className="map">
        <div className="google-map">
          <iframe
            allowFullScreen="false"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2992.420684708584!2d2.127489376060693!3d41.4083857712973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a49816d2174de3%3A0xdf19845b9ce0e77d!2sC%2F%20de%20Sant%20Joan%20de%20la%20Salle%2C%2042%2C%2008022%20Barcelona!5e0!3m2!1sen!2ses!4v1698685659984!5m2!1sen!2ses"
          ></iframe>
        </div>
    </div>
);

export default Map
