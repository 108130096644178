import React from "react";
import "./Instrace.css";

import card1 from "../../Assets/lab/instrace/card1.png";
import card2 from "../../Assets/lab/instrace/card2.png";

import boxes from "../../Assets/lab/instrace/boxes.jpg";
import hand from "../../Assets/lab/instrace/hand.png";


import logo from "../../Assets/lab/instrace/logo.png";

const importAll = (r) => r.keys().map(r);
const icons = importAll(require.context('../../Assets/lab/instrace/icons/', false, /\.(png|jpe?g|svg|webp)$/));



const Instrace = () => {

    return (
        <div className={"content"}>
            <section className={"first"} >
                <div className={"first-name"}>
                    <h1>{"Instrace"}</h1>
                    <svg fill="#ea8c3c" height="60px" viewBox="5 5 20 20" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
                        <path d="M15 10l-9 5V5l9 5z"  />
                    </svg>
                </div>
                <div className={"first-challenge"}>
                    <h4>THE CHALLENGE</h4>
                    <p>Instrace Label represents a groundbreaking solution in international logistics management, providing a real-time tracking system using intelligent labels.</p>
                    <p>This innovative solution revolutionizes how companies manage their global shipments.</p>
                    <p>Quickly and easily, businesses can instantly locate their goods, proactively avoiding incidents, reducing costs, and minimizing losses.</p>
                    <p>This, in turn, elevates their levels of security, reliability, and competitiveness. The provision of precise real-time information empowers companies to deliver enhanced services to their customers.</p>
                </div>
            </section>
            <section className={"second"} >
                <div className={"second-card-left"}>
                    <img src={card1} alt={"card"} className={"second-card"}/>
                </div>
                <div className={"second-card-right"}>
                    <img src={card2} alt={"card"} className={"second-card"}/>
                    <img src={logo} alt={"Instrace"} className={"second-logo"}/>
                </div>
            </section>
            <section className={"third"} >
                <div className={"third-left"}>
                    <h3>{"“Our solution provides accurate, up-to-the-minute information.”"}</h3>
                    <div className={"third-left-bottom"}>
                        <p>For specific scenarios, additional technologies can be
                            modularly integrated to tailor the solution to the client's
                            needs.</p>
                        <div className={"third-left-bottom-icons"}>
                            {icons.map((icon, index) => (
                                <img key={index} src={icon} alt={"icon"} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className={"third-right"}>
                    <h4>THE SOLUTION</h4>
                    <p>
                        Instrace Labels. Seamlessly embedding traceability technology
                        into paper, our discreet yet powerful smart tags redefine the
                        landscape of logistics.
                    </p>
                    <p> With Instrace, businesses gain real-time visibility into the
                        journey of their goods, optimizing routes, reducing transit times,
                        and minimizing losses. Enhancing stock management, our
                        solution provides accurate, up-to-the-minute information,
                        preventing stockouts and excess inventory.
                    </p>
                    <p>
                        Compliant with international standards, Instrace ensures
                        seamless customs processes through digital documentation.
                    </p>
                    <img src={boxes} alt={"boxes"}/>
                </div>
            </section>
            <section className={"fourth"} >
                <div className={"fourth-left"}>
                    <img src={logo} alt={"logo"}/>
                    <p>
                        Instrace is linked to an app that allows you to track your assets in real-time.
                    </p>
                    <p>
                        The application enables you to trace the location of your products
                        quickly, easily, and conveniently. All the information you need will
                        be available instantly.
                    </p>
                    <p>
                        You will never lose sight of your shipments, ensuring efficient
                        tracking and comfortable management at all times.
                    </p>
                </div>
                <div className={"fourth-right"}>
                    <img src={hand} alt={"hand"} className={"hand"}/>
                </div>
            </section>
        </div>

    );
};

export default Instrace;