
let homeContent = [
  {
    id: 0,
    title: "header",
    sections: [
      {
        id: 6,
        name: "Careers",
        endpoint: "https://cactusengineering.odoo.com/jobs",
        scrollName: "careers-scroll"
      },
      {
        id: 1,
        name: "Projects",
        endpoint: "/",
        scrollName: "projects-scroll"
      },
      {
        id: 5,
        name: "Clients",
        endpoint: "/",
        scrollName: "clients-scroll"
      },
      {
        id: 2,
        name: "Services",
        endpoint: "/",
        scrollName: "services-scroll"
      },
      {
        id: 3,
        name: "Sustainability",
        endpoint: "/",
        scrollName: "sustainability-scroll"
      },
      {
        id: 4,
        name: "People",
        endpoint: "/",
        scrollName: "people-scroll"
      },
    ]
  },
  {
    id: 1,
    cactusName: "cactus",
    title: "",
    subtitle: ""
  },
  {
    id: 2,
    title: "PHILOSOPHY",
    subtitle: "",
    text: "At Cactus we strive to make the development of technology ecologically and socially transformative.",
    endpoint: "/",
    isLeft: true,
    image: "Philosophy",
    scrollName: "philo-scroll"
  },
  {
    id: 3,
    title: "PROJECTS",
    subtitle: "Engineering as a Service",
    description: "During more than 15 years, we have exceled at building hardware, firmware and software around our customers needs in a continuous delivery work methodology. We are a multidisciplinary engineering consultancy with vast experience in software and hardware development, specialized in end-to-end electronic solutions that cover the whole IoT market range:  Healthcare, Energy, Agriculture, Finance, Manufacturing, Retail, Hospitality, Transportation and Logistics.",
    projects: [
      {
        name: "Renfe",
        image: "Renfe",
        description: "Real-time user data monitoring. Predictive maintenance for facilities management." +
            "#5G #LoRa #AI cloud  #hardware",
      },
      {
        name: "Taiga",
        image: "Taiga",
        description: "Connected water dispenser. CE certification. Large scale production management." +
            "#CatM1 #UltrasonicSensors #ImageRecognition #cloud #hardware",
      },
      {
        name: "Datalogger",
        image: "Datalogger",
        description: "Professional solution for irrigation and fertigation. Real-time monitoring." +
            "#NBIoT #UltraLowPower #Sensoring",
      },
      {
        name:  "BIM6D (digital twin)",
        image: "BIM6D",
        description: "Providing real-time data to a professional digital twin platform." +
            "#5G #LoRa #Sensoring #hardware",
      },
      {
        name:  "Neuboots",
        image: "Neuboots",
        description: "Smart and connected ski boot." +
            "#NBIoT #UltraLowPower #Sensoring",
      },
      {
        name:  "Enapri",
        image: "enapri",
        description: "Smart light real-time control system." +
            "#Matter #App #homekit #hardware",
      },
      {
        name:  "Flexible Led Matrix",
        image: "led_matrix",
        description: "Customized state of the art flexible LED Matrix for automotive purposes." +
            "#Design #Hardware #Firmware #ImageProcessing",
      },
      {
        name:  "Flipdots",
        image: "flipdots",
        description: "State of the art electromagnetic attracted flip dots." +
            "#Design #IA #Hardware #Embedded",
      },
      {
        name:  "Watchman Door",
        image: "home",
        description: "Connected door handle." +
            "#NBIoT #Bluetooth #Hardware #Firmware #Zephyr #UltraLowPower",
      },
      {
        name:  "Cashkeeper",
        image: "cashkeeper",
        description: "Self-counting Industrial Cash Register." +
            "#Hardware #Embedded",
      },
    ]
  },
  {
    id: 4,
    title: "CLIENTS",
    subtitle: "They trust us"
  },
  {
    id: 5,
    title: "SERVICES",
    subtitle: "This is what we can do for you\n",
    scrollName: "projects-scroll",
    text: "We believe in a collaborative problem-solving approach. Our experienced team of engineers and developers work closely with our clients to gain a deep understanding of their needs and challenges. Through a process of active listening, ideation, and prototyping, we develop custom solutions that are tailored to the unique requirements of each client.",
    content: [
      {
        name: "Product Design",
        sections: [
          {
            name: "Hardware",
            description: "We develop customized hardware solutions that are tailored to the specific needs of our clients.",
            bullets: [
              "Electronic circuit design",
              "PCB layout design",
              "Component selection and sourcing",
              "Prototyping and testing",
              "Managing small, medium and large scale productions"
            ]
          },
          {
            name: "Software embedded",
            description: "We create software solutions that are embedded in hardware, such as microcontrollers and other devices.",
            bullets: [
              "Firmware development",
              "Real-time operating systems (RTOS) integration",
              "Low-level driver development",
              "Embedded Linux development",
              "Middleware development"
            ]
          },
          {
            name: "Cloud computing",
            description: "We offer cloud-based solutions that enable our clients to access their data in real time.",
            bullets: [
              "Platform design",
              "Infrastructure as a Service (IaaS)",
              "Platform as a Service (PaaS)",
              "Software as a Service (SaaS)",
              "Database as a Service (DBaaS)"
            ]
          },
        ]
      },
      {
        name: "Certifications",
        sections: [
          {
            name: "CE & FCC",
            description: "We offer certification services to meet the market regulations applied to your product.",
            bullets: [
              "Harmonics and Flicker",
              "Conducted and radiated emissions",
              "ESD (Electrostatic Discharge Immunity)",
              "Electromagnetic field, fast transient/burst and surge immunity",
              "Immunity to conducted disturbances, induced by radio-frequency fields",
              "Power frequency magnetic field immunity",
              "Voltage dips and short interruptions immunity",
            ]
          },
        ]
      }
    ]
  },
  {
    id: 6,
    title: "SUSTAINABILITY",
    subtitle: "How do we implement sustainability?\n",
    text: "At Cactus, sustainability is at the core of everything we do. As a company, we understand that our actions have an impact on the environment and we strive to minimize our own carbon footprint and make a positive impact on the world. We have implemented several sustainable practices in our operations, such as using renewable energy sources, recycling and reducing waste, and promoting sustainable transportation among our employees.",
    endpoint: "/",
    isLeft: true,
    image: "Sustainability",
    scrollName: "sustainability-scroll"
  },
  {
    id: 7,
    title: "PEOPLE",
    subtitle: "Join our community",
    text: "Our team is the backbone of our success. We believe in promoting an inclusive and respectful culture where everyone feels valued and supported. We commit to the growth and professional development of our employees by offering diverse opportunities for learning, nurturing creative thinking, and encouraging innovative approaches whilst fostering a cohesive community that embraces our values.",
    endpoint: "/",
    isLeft: false,
    image: "People",
    scrollName: "people-scroll"
  },
  {
    id: 8,
    title: "Quote",
    quote: "Efficiency is our passion. Whether developing hardware, cloud infrastructures or custom apps, we always design the solution that requires fewer resources, thus saving energy, our client's money and providing a contribution to a more sustainable world."
  },
  {
    id: 9,
    title: "Contact",
    subtitle: "How can we help you?",
    buttonText: "Contact Us",
  },
  {
    id: 10,
    title: "Footer",
    contact: {
      email: "info@cactusiot.com",
      phone: "(+34) 644 55 27 58",
      address: "C/ Sant Joan de la Salle, 42",
      addressCode: "08022 Barcelona"
    },
    socials: [
      {
        name: "LinkedIn",
        link: "https://www.linkedin.com/company/cactusiot"
      },
      {
        name: "Instagram",
        link: "https://www.instagram.com/cactusengineering/"
      }
    ]
  }
]


export default homeContent;
