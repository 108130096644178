import React from "react";
import "./Footer.css";
import MailIcon from '../../Assets/icons/mail-icon.png'
import TelfIcon from '../../Assets/icons/telf.png'
import LocationIcon from '../../Assets/icons/location.png'
import Logo from '../../Assets/icons/logo-with-name_black.svg'
import agenda2030 from '../../Assets/images/footer-logos/agenda2030.png'

import LinkedIn from '../../Assets/icons/Recurso 7@2x.png'
import Instagram from '../../Assets/icons/Recurso 6@2x.png'

import Espana from '../../Assets/images/footer-logos/Logotipo_del_Ministerio_de_Ciencia_e_Innovación.svg.png'
import apte from '../../Assets/images/footer-logos/Logo-APTE-300x149.png'
import cdti from '../../Assets/images/footer-logos/cdti.png'
import enisa from '../../Assets/images/footer-logos/enisa.png'

function getImage(e) {
    if (e === "LinkedIn") {
        return LinkedIn;
    }else if(e === "Instagram"){
        return Instagram;
    }
    return LinkedIn;
}

const Footer = ({clau, contact, socials}) => {

    return (
        <div key={clau} className="footer-section">
            <div className={"footer-info"}>
                <div className={"f-info"}>
                    <div className={"imageRow"}>
                        <img src={MailIcon} alt="mail-icon" />
                        <a href={"mailto:info@cactusiot.com"}>{contact.email}</a>
                    </div>
                    <div className={"imageRow"}>
                        <img src={TelfIcon} alt="telf-icon" />
                        <a href={"tel:667735767"}>{contact.phone}</a>
                    </div>
                    <div className={"imageRow addressInfo"}>
                        <img src={LocationIcon} alt="location-icon" />
                        <a className={"addrss-column"} href={"https://maps.google.com/?q=C/ de Sant Joan de la Salle, 42, 08022 Barcelona"}>
                            <p>{contact.address}</p>
                            <p>{contact.addressCode}</p>
                        </a>
                    </div>
                </div>

                <div className={"f-socials"}>
                    <ul>
                        {socials.map((element, index) => {
                            return (
                                <li key={index} className={"socialRow"}>
                                    <img src={getImage(element.name)} alt="location-icon" />
                                    <a href={element.link} target="_blank" rel="noopener noreferrer">{element.name}</a>
                                </li>
                            );
                        })}
                   </ul>
                </div>
                <div className={"f-logo"}>
                    <img className={"cactusLogo"} src={Logo} alt="cactus-icon" />
                </div>
                <div className={"f-agenda"}>
                    <img src={agenda2030} alt="agenda2030-icon" />
                    <img src={Espana} alt="españa-icon" />
                    <img src={apte} alt="apte-icon" />
                </div>
                <div className={"f-enisa"}>
                    <img src={enisa} alt="enisa-icon" />
                    <img src={cdti} alt="cdti-icon" />
                </div>


            </div>
            <div className={"copyright-notice"}>
                <p>Copyright © {(new Date().getFullYear())} cactus</p>
                <div className={"spacer"}></div>
                <div className={"copy-links"}>
                    {/* TODO -> Add the correct link to these anchors when the corresponding pages are completed  */}
                    <a>Privacy Policy</a>
                    <a>Terms & Conditions</a>
                </div>
                <p className={"version"}>v2.0.0</p>
            </div>
        </div>
    );
};

export default Footer;
