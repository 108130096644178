import React, {useEffect, useRef} from "react";
import "./Projects.css";
import { Element } from 'react-scroll';
import Slider from "react-slick";
import images from "./imageImporterProjects"



const Projects = ({clau, element, info}) => {

    element.forEach(el => {
        images.forEach(img => {
            if (img.includes(el.image)) {
                el.image = img;
            }
        });
    });

    const elements = Array.from({ length: element.length }, (_, index) => index);

    const carouselRef = useRef(null);
    const isScrollingRef = useRef(false);


    useEffect(() => {
        const carouselElement = carouselRef.current.innerSlider.list;

        const handleWheelScroll = (event) => {
            const { deltaX, deltaY } = event;

            if (Math.abs(deltaX) < Math.abs(deltaY)) {
                // Vertical scroll, let it proceed
                return;
            }

            event.preventDefault();

            if (!isScrollingRef.current) {
                isScrollingRef.current = true;

                if (deltaX > 0) {
                    carouselRef.current.slickNext();
                } else {
                    carouselRef.current.slickPrev();
                }

                setTimeout(() => {
                    isScrollingRef.current = false;
                }, 800);
            }
        };

        carouselElement.addEventListener('wheel', handleWheelScroll);

        return () => {
            carouselElement.removeEventListener('wheel', handleWheelScroll);
        };
    }, []);

    const settings = {
        dots: true,
        className: "slider variable-width",
        adaptiveHeight: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        draggable: true,
        swipeToSlide: true,
        swipe: true,
        touchMove: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],

    };

    return (
        <Element key={clau} name='projects-scroll'>
                <div key={clau + "--P"} className="projects-section">
                    <div className={"projects-title"}>
                        <h1>{info.title}</h1>
                        <h2>{info.subtitle}</h2>
                        <h3>{info.description}</h3>
                        <hr></hr>
                    </div>
                    <Slider ref={carouselRef} {...settings}>
                        {elements.map((e) => (
                            <div key={e} className="card card__collection ">
                                <div className="cards cards--two">
                                    <img
                                        src={`${element[e].image}`} className="img-responsive" alt={`Cards ${e}`} />
                                    <span className="cards--two__rect"></span>
                                    <span className="cards--two__tri"></span>
                                    <p>{element[e].description}</p>
                                </div>
                                <div className={"card-descriptor"}>
                                    <h3>{element[e].name}</h3>
                                    <svg fill="#000000" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 10l-9 5V5l9 5z"/></svg>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
        </Element>
    );
};

export default Projects;
