import React, {useEffect, useState} from "react";
import "./Header.css";
import {Link as LinkScroll} from "react-scroll";
import {useNavigate, Link as LinkRouter} from "react-router-dom";
import cactus_lab_image from "../../Assets/lab/lab_white.svg";
import Logo from "../../Assets/icons/cactus.svg";

const Header = ({clau, sections}) => {

    const navigate = useNavigate();

    const contact = () =>{
        localStorage.setItem('visitedFromContactPage', true);
        navigate('/contact');
    }

    const lab = () =>{
        navigate('/lab-projects');
    }

    const home = () =>{
        navigate('/');
    }


    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if(window.location.pathname==='/'){
            checkVisibility();

            window.addEventListener('scroll', checkVisibility);

            return () => {
                window.removeEventListener('scroll', checkVisibility);
            };
        }else{
            if(!isVisible){
                setIsVisible(true);
            }
        }
    }, [isVisible]);

    const checkVisibility = () => {
        const rect = document.getElementById('landingHero').getBoundingClientRect();
        const visible = rect.bottom >= 250;
        setIsVisible(visible);
    };

    return (
        <nav key={clau} id="navbar" className={window.location.pathname==='/' ? "" : "no-background"}>
                <div className="nav-wrapper">
                    <div className={window.location.pathname === '/' ? "logo" : "blackText logo"}>
                        <img src={Logo} alt="Logo" className={`${!isVisible ? 'blackFont' : ''}`} onClick={home}/>
                    </div>
                    <div className={"pps"}></div>
                    <ul id="menu">
                        {sections.map((element, index) => {
                            return (
                                <li key={`${index}-${element.name}`} className={window.location.pathname==='/' ? "" : "blackText"}>
                                    {element.endpoint !== "/" ? <a href={element.endpoint} >{element.name}</a> : (
                                        window.location.pathname==='/' ?
                                            <LinkScroll to={element.scrollName} smooth={true} duration={1000} offset={0}>
                                                {element.name}
                                            </LinkScroll>
                                            :
                                            <LinkRouter to="/" state={{ targetElement: element.scrollName }}>{element.name}</LinkRouter>
                                        )
                                    }

                                </li>
                            )
                        })}
                        <li key={sections.length + 1}>
                            <button className="header-lab-button" onClick={lab}>
                                <img src={cactus_lab_image}  alt={"Cactus Lab"} className={"img_lab"}/>
                            </button>
                        </li>
                        <li key={sections.length + 2}>
                            <button className="header-contact-button" onClick={contact}>Get in touch</button>
                        </li>
                    </ul>
                </div>
            </nav>

    );
};

export default Header;

