import React from "react";
import "./ContactInfo.css";
import Map from "../GMap/Map";
import InformationItem from "../InformationItem/InformationItem";
import MailIcon from "../../Assets/icons/mail-icon.png";
import TelfIcon from "../../Assets/icons/telf.png";
import LocationIcon from "../../Assets/icons/location.png";
import Slide from 'react-reveal/Slide';

const ContactInfo = () => {

    const location = {
        address: 'C/ de Sant Joan de la Salle, 42, 08022 Barcelona',
        lat: 41.408386,
        lng: 2.130064
    }
    return (
        <div className="contact-info">
            <Slide right>
                <Map location={location} zoomLevel={16} />
                <InformationItem
                    icon ={LocationIcon}
                    title={"Visit us"}
                    subtitle={"Come say hello to our office HQ"}
                    href={"https://maps.google.com/?q="+location.address}
                    linkedContent = {"C/ Sant Joan de la Salle, 42, 08022 Barcelona"}
                />
                <InformationItem
                    icon ={TelfIcon}
                    title={"Call us"}
                    subtitle={"Mon-Fri from 8am to 5pm"}
                    href={"tel:644552758"}
                    linkedContent = {"(+34) 644 55 27 58"}
                />
                <InformationItem
                    icon ={MailIcon}
                    title={"Chat with us"}
                    subtitle={"Our friendly team is here to help"}
                    href={"mailto:info@cactusiot.com"}
                    linkedContent = {"info@cactusiot.com"}
                />
            </Slide>
        </div>
    );
};

export default ContactInfo;
