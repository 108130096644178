import React , { Component }from "react";
import "./Quote.css";

class Quote extends Component {

    render(){
        return (
            <div key={this.props.clau} className={"centered"}>
                    <div className={"quote"}>
                            <div className={"quote-symbol"}>
                                <i className="fa fa-quote-left" aria-hidden="true"></i>
                            </div>
                            {this.props.quote}
                            <div className={"quote-symbol bottom-quote"}>
                                <i className="fa fa-quote-right" aria-hidden="true"></i>
                            </div>
                    </div>
            </div>
        );
    }
}

export default Quote;

