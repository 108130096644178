import React from "react";
import "./ContactSection.css";
import LogoBlanc from "../../Assets/icons/logo-blanc.png";
import { useNavigate } from "react-router-dom";
import background from "../../Assets/images/cactus.jpg";

const ContactSection = ({clau, subtitle, buttonText}) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/contact")
    }

    return (
        <div key={clau} className="contact-section" style={{ backgroundImage: `url(${background})`}}>
            <h1>{subtitle}</h1>
            <div className={"contact-action"}>
                <img className={"cactusLogo"} src={LogoBlanc} alt="cact-logo-white" />
                <button className="contact-button" onClick={handleClick} >{buttonText}</button>
            </div>
        </div>
    );
};

export default ContactSection;
