import React from "react";
import "./LabProjectStaticImage.css";

const LabProjectStaticImage = ({image, title, project}) => {

    return (
        <div key={project} className={"lab-project-static"}>
            <div >
                <img src={image} alt={title}/>
                <div className={"lab-project-text"}>
                    <h1>{project}</h1>
                    <h3>{title}</h3>
                </div>
            </div>
        </div>

    );

}

export default LabProjectStaticImage;
