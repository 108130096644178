import React from "react";
import {useNavigate} from "react-router-dom";
import "./LabProject.css";


const LabProject = ({image, title, project}) => {

    const navigate = useNavigate();

    const navigateProject = () =>{
        const id = project.toString().toLowerCase();
        navigate('/project/' + id);
    }

    return (
        <div key={project} className={"lab-project"} onClick={navigateProject}>
            <div className={"lab-project-overlay"}>
                <img src={image} alt={title}/>
                <div className={"lab-project-text"}>
                    <h1>{project}</h1>
                    <h3>{title}</h3>
                </div>
            </div>
        </div>

    );

}

export default LabProject;
