import {Routes, Route, Navigate} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Home from "./Components/Home";
import ContactUs from "./Components/ContactUs";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsConditions from "./Components/TermsConditions";
import {CookieConsent} from "react-cookie-consent";
import * as ReactGA from "react-ga";
import Project from "./Components/Project";
import LabProjects from "./Components/LabProjects";

function App() {

    return (
        <div id="app">
            <HelmetProvider>
                        <Routes>
                            <Route exact path="/" element={<><Helmet><title>Cactus - Sustainable Engineering Services</title></Helmet><Home /></>} />
                            <Route exact path="/contact" element={<><Helmet><title>Contact - cactus</title></Helmet><ContactUs /></>} />
                            <Route exact path="/privacy-policy" element={<><Helmet><title>Privacy Policy - cactus</title></Helmet><PrivacyPolicy /></>} />
                            <Route exact path="/terms-conditions" element={<><Helmet><title>Terms and Conditions - cactus</title></Helmet><TermsConditions /></>} />
                            <Route exact path="/lab-projects" element={<><Helmet><title>Cactus Lab - cactus</title></Helmet><LabProjects /></>} />
                            <Route exact path={"/project/:id"} element={<><Helmet><title>Project - cactus</title></Helmet><Project /></>} />
                            <Route path='*' element={<Navigate to='/' />} />
                        </Routes>
                <CookieConsent
                    buttonText="I understand"
                    declineButtonText={"Decline"}
                    onAccept={(acceptedByScrolling) => {
                        if (!acceptedByScrolling) {
                            const isInitialized = localStorage.getItem('isInitialized');
                            if(!isInitialized){
                                localStorage.setItem('isInitialized', true);
                                ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
                                ReactGA.pageview(window.location.pathname);
                            }
                        }
                    }}
                    style={{ background: "#F6F7F9", color: "#000000", fontWeight: "600px" }}
                    declineButtonStyle={{background: "#F05552", color:"#FFFFFF", fontSize: "13px", borderRadius: "5px", fontWeight: "bolder",}}
                    buttonStyle={{ background: "#29B473", color:"#FFFFFF", fontSize: "13px", borderRadius: "5px", fontWeight: "bolder", }}
                    enableDeclineButton
                >This website uses cookies to enhance the user experience.</CookieConsent>
            </HelmetProvider>
        </div>
    );
}

export default App;
