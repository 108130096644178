import React, {useEffect} from "react";
import Header from "./Header/Header";
import * as ReactGA from "react-ga";
import {getCookieConsentValue} from "react-cookie-consent";
import TermsAndConditionsContent from "./TermsPolicyContent/TermsNConditionsContent";
import Footer from "./Footer/Footer";
import homeContent from "../Assets/jsons/home-content";
import MobileMenu from "./MobileMenu/MobileMenu";


const TermsConditions = () => {

    useEffect(() => {
        if(getCookieConsentValue()){
            const isInitialized = localStorage.getItem('isInitialized');
            if(isInitialized === true){
                ReactGA.pageview('/terms-conditions');
            }
        }
    }, []);

    return (
        <>
            <Header
                key={homeContent.find(section => section.title === "header").id}
                clau={homeContent.find(section => section.title === "header").id}
                sections={homeContent.find(section => section.title === "header").sections}
            />
            <TermsAndConditionsContent/>
            <Footer
                key={homeContent.find(section => section.title === "Footer").id}
                clau={homeContent.find(section => section.title === "Footer").id}
                contact={homeContent.find(section => section.title === "Footer").contact}
                socials={homeContent.find(section => section.title === "Footer").socials}
            />
            <MobileMenu
                sections={homeContent.find(section => section.title === "header").sections}
            />
        </>
    );
};

export default TermsConditions;