import React, {useRef} from "react";
import "./ContactForm.css";
import emailjs, {init} from "@emailjs/browser";
import {toast} from "react-toastify";
import {getCookieConsentValue} from "react-cookie-consent";
import * as ReactGA from "react-ga";

const ContactForm = () => {

    init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
    const form = useRef();

    const clearForm = () => {
        document.getElementById("nomField").value = "";
        document.getElementById("mailField").value = "";
        document.getElementById("messageField").value = "";
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.info('Sending email...', {
            position: "bottom-center",
            autoClose: 1000000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });

        if(getCookieConsentValue()){
            const isInitialized = localStorage.getItem('isInitialized');
            if(isInitialized){
                ReactGA.event({
                    category: 'User',
                    action: 'Sent Email'
                });
            }
        }

        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY).then(
            (result) => {
                toast.dismiss();
                toast.success('Successfully sent!', {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                clearForm();
            },
            (error) => {
                toast.dismiss();
                toast.error('Error sending email. Please try again.', {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                clearForm();
            }
        );
    };

    return (
        <div className="contact-form">

            <h1>Got ideas? We've got the skills. Let's team up.</h1>
            <h4>Tell us more about yourself and what you've got in mind.</h4>
            <form ref={form} onSubmit={handleSubmit}>
                <div className="input">
                    <input id="nomField" type="text" className="input-field" name="name" placeholder={"  "} required/>
                    <label className="input-label">Name</label>
                </div>
                <div className="input">
                    <input id="mailField" type="email" className="input-field" name="email" placeholder={"  "} required/>
                    <label className="input-label">you@company.com</label>
                </div>
                <div className="input">
                    <textarea id="messageField" type="text" className={"input-field"} name="message" placeholder={"  "} required />
                    <label className="input-label">Tell us a little about the project</label>
                </div>
                <button type="submit" className="contact-button contactform">Let's get started</button>
            </form>

        </div>
    );
};

export default ContactForm;
