import React from "react";
import "./LabExplanation.css";

import image from "../../Assets/lab/lab_white.svg";


const LabExplanation = ({title,text}) => {

    return (
        <div  className={"lab-explanation"} >
            <img src={image} alt={"cactus lab"} className={"lab-explanation-img"}/>
            <p className={"lab-explanation-text"} style={{fontWeight: 700}}>{title}</p>
            <p className={"lab-explanation-text"}>{text}</p>
        </div>

    );

}

export default LabExplanation;