import React from "react";
import "./Philosophy.css";

const Philosophy = ({clau, element}) => {

    return (
        <div key={clau} className={"philo"}>
            <h4>{element.title}</h4>
            <h3>{element.text}</h3>
        </div>

    );

}

export default Philosophy;

