import React from "react";
import "./MobileMenu.css";
import {Link as LinkScroll} from "react-scroll";
import {Link as LinkRouter, useNavigate} from "react-router-dom";
import LogoName from "../../Assets/icons/logo-with-name_white.svg";

const MobileMenu = ({sections}) => {

    const toggleMenu = () => {
        var overlay = document.getElementById('overlay');
        overlay.classList.contains("visible") ? setTimeout(function(){overlay.classList.remove('visible')}, 500) : overlay.classList.add('visible');
        document.getElementById('menuBurguer').classList.toggle('fixed');
        document.getElementById('burgerMenu').classList.toggle('open');
        var  _class = 'open';
        var nav = document.getElementById('barraNavegacio')
        nav.classList.contains(_class)
            ? nav.classList.remove  (_class)
            : nav.classList.add     (_class);
    };
    const navigate = useNavigate();

    const goToEndpoint = (e, scrollName) =>{
        toggleMenu();
        if(e === '/contact'){
            localStorage.setItem('visitedFromContactPage', true);
        }
        if(e === '/'){
            localStorage.setItem('targetElement', scrollName);
        }
        setTimeout(function(){navigate(e)}, 500);
    }

     return (
         <>
           <div id="menuBurguer" className={"mobileMenu"}>
                <div id="burgerMenu" onClick={toggleMenu} className="menu boto" data-menu="1">
                    <div className="icon-left"></div>
                    <div className="icon-right"></div>
                </div>
           </div>
                <div id="overlay" className="app">
                        <div id="barraNavegacio" className="nav">
                            <div className="nav-content">
                                <div className="background">
                                    <div className="portion"></div>
                                    <div className="portion"></div>
                                    <div className="portion"></div>
                                </div>
                                <div className={"navigation"}>
                                    <div className="logo">
                                        <a href="/"><img src={LogoName} alt={"cactus"}/></a>
                                    </div>
                                    <ul>
                                        {sections.map(element => {
                                            return (
                                                <li key={element.id}>
                                                    {element.endpoint !=="/" ?

                                                        <LinkRouter to={element.endpoint} state={{ targetElement: element.scrollName }}>
                                                            <button onClick={() => goToEndpoint(element.endpoint)}>{element.name}</button>
                                                        </LinkRouter>

                                                        :
                                                        (
                                                            window.location.pathname==='/' ?
                                                                <LinkScroll to={element.scrollName} smooth={true} duration={1000} offset={-30} delay={500}>
                                                                    <button onClick={() => goToEndpoint(element.endpoint, '')}>{element.name}</button>
                                                                </LinkScroll>
                                                                :
                                                                <button className={"buttonMobile"} onClick={() => goToEndpoint('/', element.scrollName)}>{element.name}</button>
                                                        )
                                                    }
                                                </li>
                                            )
                                        })}
                                        <li><button className={"cactus-lab"} onClick={() => goToEndpoint('/lab-projects')} >CACTUS LAB</button></li>
                                        <li><button className={"contact-link"} onClick={() => goToEndpoint('/contact')} >CONTACT US</button></li>
                                    </ul>

                                </div>

                            </div>
                        </div>
                    </div>

         </>

        );
    };


export default MobileMenu;

