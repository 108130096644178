import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import {ga} from "react-ga";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import {getCookieConsentValue} from "react-cookie-consent";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <ScrollToTop/>
        <App/>
    </BrowserRouter>
);

function sendToAnalytics({id, name, value}) {
    if (getCookieConsentValue()) {
        const isInitialized = localStorage.getItem('isInitialized');
        if (isInitialized === true) {
            ga('send', 'event', {
                eventCategory: 'Web Vitals',
                eventAction: name,
                eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
                eventLabel: id,
                nonInteraction: true,
            });
        }
    }
}

reportWebVitals(sendToAnalytics);
