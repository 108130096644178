import React, {Component} from "react";
import "./SustainableServices.css";
import Logo from '../../Assets/icons/logo-cactus-sustainable_white.svg';

class SustainableServices extends Component {
    render() {
        return (
            <div key={this.props.clau} id="landingHero" className={"landing-hero"}>
                <div className={"landing-img"}>
                    <img className={"cactusLogo"} src={Logo} alt="Cactus icon - Sustainable engineering services"/>
                </div>
            </div>
        );
    }
}

export default SustainableServices;

