import React, {useEffect} from "react";
import Header from "./Header/Header";
import MobileMenu from "./MobileMenu/MobileMenu";
import Footer from "./Footer/Footer";
import ContactForm from "./ContactForm/ContactForm";
import ContactInfo from "./ContactInfo/ContactInfo";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import * as ReactGA from "react-ga";
import {getCookieConsentValue} from "react-cookie-consent";
import homeContent from "../Assets/jsons/home-content";


const ContactUs = () => {

    useEffect(() => {
        if(getCookieConsentValue()){
            const isInitialized = localStorage.getItem('isInitialized');
            if(isInitialized === true){
                ReactGA.pageview('/contact');
            }
        }
    }, []);

    return (
        <>
            <Header
                key={homeContent.find(section => section.title === "header").id}
                clau={homeContent.find(section => section.title === "header").id}
                sections={homeContent.find(section => section.title === "header").sections}
            />
            <div className="contact-container">
                <ContactInfo/>
               <ContactForm/>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Footer
                key={homeContent.find(section => section.title === "Footer").id}
                clau={homeContent.find(section => section.title === "Footer").id}
                contact={homeContent.find(section => section.title === "Footer").contact}
                socials={homeContent.find(section => section.title === "Footer").socials}
            />
            <MobileMenu
                sections={homeContent.find(section => section.title === "header").sections}
            />
        </>
    );
};

export default ContactUs;