import { Component }from "react";
import "./Section.css";
import { Element } from 'react-scroll';
import Slide from 'react-reveal/Slide';
//import pdfFile from "../../Assets/pdf/Presentacio_corporativa_Cactus_02_proposta.pdf"

class Section extends Component {
    render(){
        return (
            <Element key={this.props.clau} name={this.props.scrollName}>

                    <div key={this.props.clau + "--s"} className={ this.props.isLeft ? (this.props.id !== 2 ? "section isLeft colorGray" : "section isLeft philoText") : "section"}>
                        {(this.props.id !== 2 ? (this.props.isLeft ?<>
                            <div className="section-img">
                                <h4>{this.props.title}</h4>
                                <h1>{this.props.subtitle}</h1>
                                <div className={"centrat"}>
                                    <Slide right>
                                        <img src={this.props.image} alt={this.props.title}/>
                                    </Slide>
                                </div>

                            </div>

                            <div className="section-content">
                                <h4>{this.props.title}</h4>
                                <h1>{this.props.subtitle}</h1>
                                <h3>{this.props.text}</h3>

                                <a className={"noUnderline"} >
                                    <button className="learn-more">
                                        <span>Learn More</span>
                                        <svg fill="#000000" className="rotate-90deg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21,21H3L12,3Z"/>
                                        </svg>
                                    </button>
                                </a>

                            </div> </> : <>
                        <div className="section-img">
                            <h4>{this.props.title}</h4>
                            <h1>{this.props.subtitle}</h1>
                            <div className={"centrat"}>
                                <Slide left>
                                    <img src={this.props.image} alt={this.props.title}/>
                                </Slide>
                            </div>

                        </div>

                        <div className="section-content peopleSection">
                            <h4>{this.props.title}</h4>
                            <h1>{this.props.subtitle}</h1>
                            <h3>{this.props.text}</h3>

                            <a className={"noUnderline"}>
                                <button className="learn-more">
                                    <span>Learn More</span>
                                    <svg fill="#000000" className="rotate-90deg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21,21H3L12,3Z"/>
                                    </svg>
                                </button>
                            </a>


                        </div>
                        </>) : <><div className="section-img">
                        <h4>{this.props.title}</h4>
                        <h1>{this.props.subtitle}</h1>
                        <div className={"centrat"}>
                            <img src={this.props.image} alt={this.props.title}/>
                        </div>

                    </div>

                        <div className="section-content">
                            <h4>{this.props.title}</h4>
                            <h1>{this.props.subtitle}</h1>
                            <h3>{this.props.text}</h3>

                            <a className={"noUnderline"}>
                                <button className="learn-more">
                                    <span>Learn More</span>
                                    <svg fill="#000000" className="rotate-90deg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21,21H3L12,3Z"/>
                                    </svg>
                                </button>
                            </a>

                        </div></>)}


                    </div>

            </Element>
        );

    }
}

export default Section;

