import React from "react";
import "./LabContact.css";

const LabContact = () => {

    return (
        <div  className={"lab-contact"} >
            <div className={"lab-contact-text"}>
                <h3>Contact us</h3>
                <h1>What can we create together?</h1>
            </div>
        </div>

    );

}

export default LabContact;