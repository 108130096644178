import React, {useEffect} from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import ContactSection from "./ContactSection/ContactSection";
import MobileMenu from "./MobileMenu/MobileMenu";
import Clients from "./Clients/Clients";
import Services from "./Services/Services";
import Projects from "./Projects/Projects";
import SustainableServices from "./SustainableServices/SustainableServices";
import Quote from "./Quote/Quote";
import Philosophy from "./Philosophy/Philosophy";
import Section from "./Section/Section";
import {useNavigate, useLocation} from "react-router-dom";
import {scroller} from "react-scroll";
import { getCookieConsentValue } from "react-cookie-consent";

import CactusPeople from '../Assets/images/home/Recurso 4@2x.png'
import CactusSustainability from '../Assets/images/home/Recurso 3@2x.png'
import * as ReactGA from "react-ga";
import homeContent from "../Assets/jsons/home-content";

function getImage(e) {
    if (e === 6) {
        return CactusSustainability;
    }else if(e === 7){
        return CactusPeople;
    }
    return CactusSustainability;
}

const Home = () => {
    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        if(getCookieConsentValue()){
            const isInitialized = localStorage.getItem('isInitialized');
            if(isInitialized === true){
                ReactGA.pageview('/');
            }
        }
        const targetElement = location.state?.targetElement;
        const visitedFromContactPage = localStorage.getItem('visitedFromContactPage');
        const targetElementStorage = localStorage.getItem('targetElement');
        if ((targetElement || targetElementStorage) && visitedFromContactPage) {
            const target = targetElementStorage ? targetElementStorage : targetElement;
            location.state = null;
            scroller.scrollTo(target, {
                smooth: true,
                duration: 1000,
                delay: 200,
            });
            if(targetElementStorage){
                localStorage.removeItem('targetElement');
            }
            localStorage.removeItem('visitedFromContactPage');
        }
    }, [location]);

    return (
        <React.Fragment key={"000111"}>
            {homeContent.map((element, index) => {
                switch (element.id) {
                    case 0:
                        return (
                            <Header
                                key={index}
                                clau={element.id}
                                sections={element.sections}
                            />
                        );
                    case 1:
                        return (
                            <SustainableServices
                                key={index}
                                clau={element.id}
                                element={element}
                            />
                        );
                    case 2:
                        return (
                            <Philosophy
                                key={index}
                                clau={element.id}
                                element={element}
                            />
                        );
                    case 3:
                        return (
                            <Projects
                                key={index}
                                clau={element.id}
                                element={element.projects}
                                info={element}
                            />
                        );
                    case 4:
                        return (
                            <Clients
                                key={index}
                                clau={element.id}
                                info={element}
                            />
                        );
                    case 5:
                        return (
                            <Services
                                key={index}
                                clau={element.id}
                                element={element}
                            />
                        );
                    case 6:
                    case 7:
                        return (
                            <Section
                                key={index}
                                clau={element.id}
                                history = {navigate}
                                scrollName = {element.scrollName}
                                id = {element.id}
                                title = {element.title}
                                subtitle = {element.subtitle}
                                text = {element.text}
                                endpoint = {element.endpoint}
                                isLeft = {element.isLeft}
                                image = {getImage(element.id)}
                            />
                        );
                    case 8:
                        return (
                            <Quote
                                key={index}
                                clau={element.id}
                                quote={element.quote}
                            />
                        );
                    case 9:
                        return (
                            <ContactSection
                                key={index}
                                clau={element.id}
                                subtitle={element.subtitle}
                                buttonText={element.buttonText}
                            />
                        );
                    case 10:
                        return (
                            <Footer
                                key={index}
                                clau={element.id}
                                contact={element.contact}
                                socials={element.socials}
                            />
                        );
                    default:
                        return (<></>);
                }
            })}
            <MobileMenu
                sections={homeContent.find(section => section.title === "header").sections}
            />

        </React.Fragment>
    );
};

export default Home;