import React, {Component} from "react";
import "./InformationItem.css";

class InformationItem extends Component {

    render(){
    return (
        <div className="information-item">
            <div className={"information-item-bg"}>
                <img src={this.props.icon} alt="telf-icon" />
            </div>
            <div>
                <h2>{this.props.title}</h2>
                <h4>{this.props.subtitle}</h4>
                <a href={this.props.href} target={"_blank"} rel={"noreferrer"}>{this.props.linkedContent}</a>
            </div>
        </div>
    );
    }
}

export default InformationItem;
