import { Component }from "react";

import React from "react";
import "./Services.css";
import Chip from "../../Assets/images/home/Recurso 2@2x.png"
import { Element } from 'react-scroll';
import {Link as LinkScroll} from "react-scroll";



class Services extends Component {

    nextPath(path) {
        this.props.history(path);
    }

    render(){

        const expand2 = (e) => {
            const parent = e.currentTarget.parentNode;
            const cards = parent.querySelectorAll('.profile');
            cards.forEach((card) => {
                if (card !== e.currentTarget) {
                    card.classList.remove('profile--expanded');
                    card.classList.add('profile--unexpanded');
                }
            });
            e.currentTarget.classList.toggle('profile--expanded');
            e.currentTarget.classList.toggle('profile--unexpanded');
        }

        return (
            <Element key={this.props.clau} name='services-scroll'>
                    <div key={this.props.clau + "--S"} className="services-section">
                        <div className={"imageCenter"}>
                            <img src={Chip}  alt={"services"}/>
                        </div>

                        <div className="s-information">
                            <div className="services-information">
                                <h4>{this.props.element.title}</h4>
                                <h1>{this.props.element.subtitle}</h1>
                                <h3>{this.props.element.text}</h3>
                            </div>
                            <div className={"servicesImage"}>
                                <img src={Chip}  alt={"services"}/>
                            </div>
                            <div className="dropdowns">
                                {this.props.element.content.map((opcio, index) => {

                                    return (
                                        <div key={index}>
                                            <h2>{opcio.name}</h2>
                                            {opcio.sections.map((element, index) => {
                                                const isLast = index === opcio.sections.length - 1;
                                                const className = isLast ? "profile last-child" : "profile";
                                                return (
                                                    <div className={className} onClick={expand2} key={index}>
                                                        <div className="profile__info">
                                                            <span>{element.name}</span>
                                                            <svg fill="#000000" version="1.1" id="Capa_1"
                                                                 width="25px" height="25px" viewBox="0 0 72.72 72.719">
                                                                <g>
                                                                    <g>
                                                                        <path d="M72.72,65.686H0L36.36,7.034L72.72,65.686z M5.388,62.686h61.943L36.36,12.727L5.388,62.686z"/>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div className="profile__data">
                                                            <p>{element.description}</p>
                                                            <ul>
                                                                {element.bullets.map(bullet => {
                                                                    return (
                                                                        <li key={bullet}>{bullet}</li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                            <LinkScroll to={this.props.element.scrollName} smooth={true} duration={1000} offset={ this.props.element.id===1 ? -200 : 0}>
                                <button className="learn-more" >
                                    <span>Learn More</span>
                                    <svg fill="#000000" className={"rotate-90deg"} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21,21H3L12,3Z"/></svg>
                                </button>
                            </LinkScroll>
                        </div>
                    </div>
            </Element>
        );
    }

    /*



    * */
}


export default Services;
