import React, {useEffect} from "react";
import {getCookieConsentValue} from "react-cookie-consent";
import * as ReactGA from "react-ga";
import Header from "./Header/Header";
import homeContent from "../Assets/jsons/home-content";
import Footer from "./Footer/Footer";
import MobileMenu from "./MobileMenu/MobileMenu";
import LabExplanation from "./LabProject/LabExplanation";
import LabProject from "./LabProject/LabProject";

import instrance_img from "../Assets/lab/instrace/main.png";
import LabContact from "./LabProject/LabContact";

const LabProjects = () => {

    useEffect(() => {
        if(getCookieConsentValue()){
            const isInitialized = localStorage.getItem('isInitialized');
            if(isInitialized === true){
                ReactGA.pageview('/lab-projects');
            }
        }
    }, []);

    return (
        <>
            <Header
                key={homeContent.find(section => section.title === "header").id}
                clau={homeContent.find(section => section.title === "header").id}
                sections={homeContent.find(section => section.title === "header").sections}
            />

            <LabExplanation title={"Welcome to CactusLab, our research and development division."}
                            text={"At the core of our company, we specialize in exploring projects that merge technology, sustainability, and artificial intelligence. In CactusLab, we drive innovation to create solutions that not only transform the industry but also contribute to a more sustainable future."} />
            <LabProject image={instrance_img}
                        title={"Seamlessly embedding traceability technology into paper"}
                        project={"Instrace"}
            />
            <LabContact />
            <Footer
                key={homeContent.find(section => section.title === "Footer").id}
                clau={homeContent.find(section => section.title === "Footer").id}
                contact={homeContent.find(section => section.title === "Footer").contact}
                socials={homeContent.find(section => section.title === "Footer").socials}
            />
            <MobileMenu
                sections={homeContent.find(section => section.title === "header").sections}
            />
        </>
    );
};

export default LabProjects;
