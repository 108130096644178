import React, {Component} from "react";
import "./Clients.css";
import images from './imageImporter';
import { Element } from 'react-scroll';
import Slider from "react-slick";

class Clients extends Component {

    render(){
        const settings = {
            dots: true,
            infinite: true,
            speed: 300,
            autoplaySpeed: 2500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            draggable: true,
            swipeToSlide: true,
            swipe: true,
            autoplay: false,
            touchMove: true,
        };


        const getColumnCount = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 1024) {
                return 4;
            } else if (screenWidth >= 768) {
                return 3;
            } else {
                return 2;
            }
        };

        const createSliderRows = () => {
            const columnCount = getColumnCount();
            const rows = [];

            for (let index = 0; index < images.length; index += columnCount*2) {
                const rowImages = images.slice(index, index + columnCount);
                const rowImages2 = images.slice(index + columnCount, index + columnCount*2);

                rows.push(
                    <div key={index}>
                        <div className="slider-row">
                            {rowImages.map((image, innerIndex) => (
                                <img
                                    key={innerIndex}
                                    src={image}
                                    alt={`client-${index}-${innerIndex}`}
                                    className="clients-section-img"
                                />
                            ))}
                        </div>
                        <div className="slider-row">
                            {rowImages2.map((image, innerIndex) => (
                                <img
                                    key={innerIndex}
                                    src={image}
                                    alt={`client-${index}-${innerIndex}`}
                                    className="clients-section-img"
                                />
                            ))}
                        </div>
                    </div>
                );
            }

            return rows;
        };

        return (
            <Element key={this.props.clau} name='clients-scroll'>
                <div className={"clients-section"} >
                    <h5>{this.props.info.title}</h5>
                    <h1>{this.props.info.subtitle}</h1>
                    <div className="clients-slider">
                        <Slider {...settings}>{createSliderRows()}</Slider>
                    </div>
                </div>
            </Element>
        );
    }
}

export default Clients;

